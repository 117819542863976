<script>
import appConfig from "@/app.config";

// import Ceklis from "./ceklis-verifikasi-tuk-asesor.vue";
// import suratPenetapan from "./preview-penetapan_tuk.vue";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    page: {
        title: "DETAIL SURAT TUGAS TEMPAT UJI KOMPETENSI",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    props: {
        id_verifikasi_tuk: {},
    },
    components: {},
    data() {
        return {
            title: "DETAIL SURAT TUGAS TEMPAT UJI KOMPETENSI",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "BUAT SURAT TUGAS TEMPAT UJI KOMPETENSI",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            detail_verifikasi_tuk: null,
            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.session_user_id,
            hak_akses: false,
            path_surat_tugas: null,
            qr_path: null,
            modalPreviewSuratTugas: false,
            path_dokumen_checklist: null,
            modalPreviewChecklist: false,
            // Modal konfirmasi password
            modalPassword: false,
            password: null,
            // proses terima tolak permohonan
            catatan: null,
            next_status: null,
            // Proses Manager Sertifikasi LSP
            showModalPilihAsesor: false,
            optionsAsesor: null,
            data_asesor: [],

            loading_store_asesor: false,
            checklist: false,
            // proses ketersediaan asesor
            showModalStatusKetersediaan: false,
            catatan_ketersediaan: null,
            index_ketersediaan_asesor: null,
            id_verifikasi_asesor: null,
            status_ketersediaan_asesor: null,
            // Proses Ketua LSP
            showModalPenerbitanSuratTugas: false,
            // Proses verifikasi tuk
            modalVerifikasiTUK: false,
            form_checklist_tuk: null,
            // proses esign
            modalEsign: false,
        };
    },
    mounted() {
        this.getDetailVerifikasi();
    },
    methods: {
        convertToRoman(num) {
            var roman = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        getDetailVerifikasi() {
            let self = this;
            self.loadingTable = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/show",
                params: {
                    id: self.id_verifikasi_tuk,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.detail_verifikasi_tuk = data_edit;
                        self.path_surat_tugas = data_edit.path_surat_tugas;
                        var list_asesor = data_edit?.verifikasi_tuk_asesor;
                        if (list_asesor) {
                            var data_asesor_raw = [];
                            $.each(list_asesor, function (indexInArray, valueOfElement) {
                                valueOfElement.is_loading = false;
                                data_asesor_raw.push(valueOfElement);
                            });
                            self.list_asesor = data_asesor_raw;
                        }
                        self.hak_akses = data_edit.hak_akses;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        addAsesor() {
            this.data_asesor.push({
                asesor_nama: null,
            });
        },
        removeAsesor(index) {
            this.data_asesor.splice(index, 1);
            this.storeAsesorVerifikator();
        },

        getAsesor() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesor-active",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesor = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        storeAsesorVerifikator() {
            let self = this;
            self.loading_store_asesor = true;
            self.path_surat_tugas = null;
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/store-asesor",
                data: {
                    id: self.detail_verifikasi_tuk.id,
                    asesor_list: JSON.stringify(self.data_asesor),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var res_fix = response.data;
                    console.log(res_fix);
                    self.path_surat_tugas = res_fix.pdf;
                    self.loading_store_asesor = false;
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        prosesPerubahanStatus(status_verifikasi = "terima") {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/ubah-status",
                data: {
                    id: self.id_verifikasi_tuk,
                    status: status_verifikasi,
                    catatan: self.catatan,
                    next_status: self.next_status?.id,
                    password: self.password,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalPilihAsesor = false;
                            self.modalPassword = false;
                            self.modalVerifikasiTUK = false;
                            self.modalEsign = false;
                            self.modalPreviewSuratTugas = false;
                            window.location.reload();
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        verifikasiKetersediaan(status_ketersediaan, id_item, index) {
            let self = this;
            self.status_ketersediaan_asesor = status_ketersediaan;
            self.id_verifikasi_asesor = id_item;
            self.index_ketersediaan_asesor = index;
            if (status_ketersediaan == "Tidak Bersedia") {
                self.showModalStatusKetersediaan = true;
            } else {
                self.prosesVerifikasiKetersediaan();
            }
        },
        prosesVerifikasiKetersediaan() {
            let self = this;
            self.showModalStatusKetersediaan = false;
            var index = self.index_ketersediaan_asesor;
            var id_verifikasi_asesor = self.id_verifikasi_asesor;
            var keterangan_ketersediaan = self.status_ketersediaan_asesor;
            self.list_asesor[index].is_loading = true;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/ubah-status-ketersediaan-asesor",
                    {
                        id_verifikasi_asesor: id_verifikasi_asesor,
                        keterangan_ketersediaan: keterangan_ketersediaan,
                        notes: self.catatan_ketersediaan,
                        verifikasi_id: self.id_verifikasi_tuk,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_fix = response.data.data;
                    console.log(res_fix);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalStatusKetersediaan = false;
                            window.location.reload();
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        // Proses persetujuan ketua LSP
        terbitkanSuratTugas() {
            let self = this;
            self.showModalPenerbitanSuratTugas = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_surat_tugas = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/generate-surat-tugas", {
                    params: {
                        id: self.detail_verifikasi_tuk.id,
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    console.log(res_fix);
                    self.qr_path = res_fix.qr;
                    self.path_surat_tugas = res_fix.pdf;

                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getFormVerifikasiTUK() {
            let self = this;
            self.modalVerifikasiTUK = true;
            self.form_checklist_tuk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/get-form-dokumen-verifikasi-tuk",
                data: {
                    verifikasi_id: self.detail_verifikasi_tuk.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var res_fix = response.data;
                    console.log(res_fix);
                    self.form_checklist_tuk = res_fix.html;
                    self.loading_store_asesor = false;
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        storeVerifikasiTUK() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-checklist");
            var mydata = new FormData(form[0]);

            mydata.append("verifikasi_id", self.id_verifikasi_tuk);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/store-ceklis-verifikasi", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalPilihAsesor = false;
                            self.modalPassword = false;
                            self.modalVerifikasiTUK = false;
                            self.modalPreviewSuratTugas = false;
                            window.location.reload();
                        }
                    });
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        previewCheclist() {
            let self = this;
            self.modalPreviewChecklist = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_dokumen_checklist = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/get-preview-dokumen-verifikasi-tuk", {
                    params: {
                        id: self.detail_verifikasi_tuk.id,
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    console.log(res_fix);
                    self.qr_path = res_fix.qr;
                    self.path_dokumen_checklist = res_fix.pdf;

                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        prosesEsign() {
            let self = this;
            self.modalEsign = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_dokumen_checklist = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/get-preview-dokumen-verifikasi-tuk", {
                    params: {
                        id: self.detail_verifikasi_tuk.id,
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    console.log(res_fix);
                    self.qr_path = res_fix.qr;
                    self.path_dokumen_checklist = res_fix.pdf;

                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td style="width: 350px"><strong>Nomor Surat Tugas Verifikasi</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_nomor }}</td>
                    </tr>
                    <tr>
                        <td><strong>Tanggal Surat Tugas Verifikasi</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_tanggal }}</td>
                    </tr>
                    <tr>
                        <td><strong>Perihal</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.perihal }}</td>
                    </tr>
                    <tr>
                        <td><strong>Skema Sertifikasi</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.skema_nama }} - {{ detail_verifikasi_tuk?.metode_nama }}</td>
                    </tr>
                    <tr>
                        <td><strong>Tempat Uji Kompetensi</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.tuk_nama }}</td>
                    </tr>
                    <tr>
                        <td><strong>Surat Tugas</strong></td>
                        <td>
                            <b-button v-on:click="modalPreviewSuratTugas = true" type="button" variant="info" class="btn-sm"><i class="fa fa-eye"></i> Preview Surat Tugas</b-button>
                        </td>
                    </tr>

                    <tr>
                        <td><strong>Status</strong></td>
                        <td>: {{ detail_verifikasi_tuk?.status_nama }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12" v-if="list_asesor?.length > 0">
            <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                <thead class="bg-dark text-center text-white">
                    <tr>
                        <th>No</th>
                        <th>Nama Asesor</th>
                        <th>Status Ketersediaan</th>
                        <th v-if="detail_verifikasi_tuk?.esign_ketua_lsp">Checklist Verifikasi TUK</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, index) in list_asesor" :key="index">
                        <td class="text-center">
                            {{ index + 1 }}
                        </td>
                        <td>
                            {{ item.asesor_nama }}
                        </td>
                        <td>
                            <div v-if="item.asesor_id == sessionUserId || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070'">
                                <div v-if="item?.is_loading"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Bersedia'">
                                    <b-button v-if="detail_verifikasi_tuk.status_id == '1c44f11e-4b95-11ee-913b-0242ac110006' && hak_akses" v-on:click="verifikasiKetersediaan('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm">
                                        <i class="bx bx-loader"></i>

                                        Roleback
                                    </b-button>
                                    <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                </div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Tidak Bersedia'">
                                    <b-button v-if="detail_verifikasi_tuk.status_id == '1c44f11e-4b95-11ee-913b-0242ac110006' && hak_akses" v-on:click="verifikasiKetersediaan('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                    <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                </div>
                                <div class="btn-group" v-else>
                                    <div v-if="detail_verifikasi_tuk.status_id == '1c44f11e-4b95-11ee-913b-0242ac110006' && hak_akses">
                                        <b-button v-on:click="verifikasiKetersediaan('Tidak Bersedia', item.id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tidak Bersedia</b-button>
                                        <b-button v-on:click="verifikasiKetersediaan('Bersedia', item.id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Bersedia</b-button>
                                    </div>
                                </div>
                                <div v-if="item.keterangan_ketersediaan == 'Tidak Bersedia' && item.notes">
                                    <br />
                                    Alasan Tidak Bersedia: {{ item.notes }}
                                </div>
                            </div>
                            <div v-else>
                                <div class="btn-group" v-if="item.keterangan_ketersediaan == 'Bersedia'">
                                    <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                </div>
                                <div class="btn-group" v-else-if="item.keterangan_ketersediaan == 'Tidak Bersedia'">
                                    <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                </div>
                                <div class="btn-group" v-else></div>
                                <div v-if="item.keterangan_ketersediaan == 'Tidak Bersedia' && item.notes">
                                    <br />
                                    Alasan Tidak Bersedia: {{ item.notes }}
                                </div>
                            </div>
                        </td>
                        <td v-if="detail_verifikasi_tuk?.esign_ketua_lsp">
                            <div v-if="detail_verifikasi_tuk?.status_id == '8c322ce4-4b95-11ee-913b-0242ac110006'">
                                <b-button v-on:click="getFormVerifikasiTUK()" type="button" variant="warning" class="btn-sm"><i class="fa fa-pencil"></i> Checklist Verifikasi</b-button>
                            </div>
                            <div v-else>
                                <b-button v-on:click="previewCheclist()" type="button" variant="info" class="btn-sm"><i class="fa fa-eye"></i> Preview Checklist Verifikasi</b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal v-model="showModalPilihAsesor" title="Proses Verifikasi" size="lg" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <label for="">Pilih Asesor Verifikasi TUK</label>
                <table class="table table-bordered">
                    <thead class="bg-dark text-light">
                        <tr>
                            <td>Nama Asesor</td>
                            <td style="width: 50px">
                                <div class="btn btn-success btn-sm" v-on:click="addAsesor"><i class="bx bx-plus"></i></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="data_asesor?.length == 0">
                            <td colspan="2">Data Tidak Tersedia</td>
                        </tr>
                        <tr v-else v-for="(val, key) in data_asesor" :key="key">
                            <td>
                                <v-select :options="optionsAsesor" label="nama_asesor" v-model="val.asesor_nama" placeholder="Pilih Asesor Verifikasi" @update:modelValue="storeAsesorVerifikator()">
                                    <template v-slot:option="option">{{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_peninjau }} Frekuensi Asesor Peninjau</template>
                                    <template v-slot:selected-option="option"> {{ option.npk }} - {{ option.nama_asesor }} - {{ option.asesor_peninjau }} Frekuensi Asesor Peninjau</template>
                                </v-select>
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" v-on:click="removeAsesor(key)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="form-group">
                    <div v-if="loading_store_asesor"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400" v-else></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin? asesor yang dipilih akan segera mendapat notifikasi validasi MUK</div>
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="prosesPerubahanStatus()" v-if="checklist"><i class="fas fa-save"></i> Simpan</button>
                <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalStatusKetersediaan" title="Alasan Tidak Bersedia" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesVerifikasiKetersediaan">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan_ketersediaan" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <!-- <form @submit.prevent="prosesVerifikasiKehadiran"> -->
            <div class="form-group">
                <!-- <label for="">Alasan Penolakan</label> -->
                <input type="password" class="form-control" v-model="password" placeholder="Password Anda" />
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="prosesPerubahanStatus('terima')"><i class="fas fa-save"></i> Konfirmasi</button>
            </div>
            <!-- </form> -->
        </div>
    </b-modal>
    <b-modal v-model="modalPreviewSuratTugas" title="Preview Surat Tugas" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <!-- <form @submit.prevent="prosesVerifikasiKehadiran"> -->

            <div class="form-group">
                <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
            </div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="modalPreviewSuratTugas = false"><i class="fas fa-times"></i> Tutup</button>
            </div>
            <!-- </form> -->
        </div>
    </b-modal>
    <b-modal v-model="modalPreviewChecklist" title="Preview Checklist Verifikasi TUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <div class="form-group">
                <iframe v-bind:src="path_dokumen_checklist" width="100%" height="400"></iframe>
            </div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="modalPreviewChecklist = false"><i class="fas fa-times"></i> Tutup</button>
            </div>
            <!-- </form> -->
        </div>
    </b-modal>
    <b-modal v-model="showModalPenerbitanSuratTugas" title="Preview Surat Tugas Verifikasi TUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas Verifikasi</strong></td>
                                    <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Tugas Verifikasi</strong></td>
                                    <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_tanggal }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="path_surat_tugas" width="100%" height="400"></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalPenerbitanSuratTugas = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="checklist" @click="(showModalPenerbitanSuratTugas = false), (modalPassword = true)"><i class="fas fa-save"></i> Terbitkan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Terbitkan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalVerifikasiTUK" title="Pengisian Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form id="form-checklist">
                <div class="form-group">
                    <div v-html="form_checklist_tuk"></div>
                </div>
            </form>
            <hr />
            <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin? hasil verifikasi akan dilanjutkan ke proses selanjutnya</div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="storeVerifikasiTUK" v-if="checklist"><i class="fas fa-save"></i> Simpan</button>
                <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalEsign" title="Preview Checklist Verifikasi TUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <div class="form-group">
                <iframe v-bind:src="path_dokumen_checklist" width="100%" height="400"></iframe>
            </div>
            <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin? hasil verifikasi akan dilanjutkan ke proses selanjutnya</div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="(modalPassword = true), (modalEsign = false)" v-if="checklist"><i class="fas fa-save"></i> Simpan</button>
                <button class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
</template>
