<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";
import DetailPraAsesmen from "./detail-pra-asesmen-asesi.vue";
import DetailVerifikasiTUK from "./detail-verifikasi-tuk.vue";
export default {
    page: {
        title: "Detail Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        DetailPraAsesmen,
        DetailVerifikasiTUK,
    },
    data() {
        return {
            title: "Detail Asesmen",
            items: [
                {
                    text: "Asesmen",
                    href: "/asesmen/asesi/daftar-asesmen",
                },
                {
                    text: "Detail Asesmen",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variabel referensi
            id: this.$route.params.id,
            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.session_user_id,
            optionsMenuParent: [],
            urutan: null,
            pendaftaran: [],
            pra_asesmen: [],
            pra_asesmen_asesor: [],
            hak_akses: false,
            sebagai_asesor: null,
            // pra_asesmen_dokumen: [],
            pra_asesmen_timeline: [],
            status_sekarang: [],
            nomor_pra_asesmen: null,
            path_surat_tugas: null,
            tanggal_pra_asesmen: new Date().toISOString().slice(0, 10),
            backend_url: process.env.VUE_APP_BACKEND_URL,
            optionsSkemaSertifikasi: [],
            optionsAsesi: [],
            optionsTUK: [],
            optionsDokumen: [],
            optionsAsesor: [],
            //modal
            showModalSimpan: false,
            showModalSuratTugas: false,
            showModalAsesor: false,
            pemilihan_asesor: [],
            kehadiran_asesor: [],
            loadingTable: false,
            asesor: [],
            optionsDokumenPendaftaran: [],
            optionsDokumenMUKTerbit: [],
            index_asesi: null,
            asesor_kompetensi_selected: null,
            status_pemilihan_asesor: false,
            //submit kehadiran asesor
            index_kehadiran_asesor: null,
            id_kehadiran_asesor: null,
            status_kehadiran_asesor: null,
            showModalStatusKehadiran: false,
            catatan_kehadiran: null,
            keterangan_kehadiran: null,
            //modal password
            showModalTerbitkanSuratBalasan: false,
            showModalPassword: false,
            username: localStorage.session_username,
            //modal preview dokumen
            path_preview_dokumen: null,
            showModalPreviewDokumen: false,
            //modal pengisian  dokumen
            showModalPengisianDokumen: false,
            //modal Hasil Rekomendasi
            modalHasilRekomendasi: false,
            //modal validasi manager sertifikasi lsp
            statusValidasi: null,
            status_validasi: false,
            showModalValidasi: false,
            asesor_kompetensi_list: [],
            asesor_peninjau_list: [],
            disclaimer_asesor: false,
            metode_asesmen: null,
            loading_store_asesor: false,
            form_dokumen_muk: null,
            formPreviewDoc: null,
            loadingformPreviewDoc: true,
            metode_portofolio: [
                // "b88cb55e-39dc-11ee-9a36-0242ac110009", // AK 01
                "b88cd214-39dc-11ee-9a36-0242ac110009", // FR.IA.08
                "b88cd5ac-39dc-11ee-9a36-0242ac110009", // FR.IA.09 (Pertanyaan Wawancara)
                "b88cd9a8-39dc-11ee-9a36-0242ac110009", // FR.IA.10 (Klarifikasi Bukti Pihak Ketiga)
                "b88c979a-39dc-11ee-9a36-0242ac110009", // FR.AK.02 REKAMAN ASESMEN
                // { id : 'b88c9b46-39dc-11ee-9a36-0242ac110009'}, // FR.APL.01 (Permohonan Sertifikasi Kompetensi)
                // { id : 'b88c9f10-39dc-11ee-9a36-0242ac110009'}, //	FR.APL.02 (Assessment Mandiri)
                // "b88ca2a8-39dc-11ee-9a36-0242ac110009", // FR.MAPA.01
                // "b88ca64a-39dc-11ee-9a36-0242ac110009", // FR.MAPA.02
                "b88ce4a2-39dc-11ee-9a36-0242ac110009", // FR.AK.05 (Laporan Assessment)
                "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.03
                "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.03
                //TAMBAHAN
                // "b88c93e4-39dc-11ee-9a36-0242ac110009", // Ceklis Konsultasi Pra Assessment
                "b88ce114-39dc-11ee-9a36-0242ac110009", // FR.AK.03
                "b88cb158-39dc-11ee-9a36-0242ac110009", // FR.AK.04
                "b88cf550-39dc-11ee-9a36-0242ac110009", // Daftar hadir Assessment.
            ],
            metode_observasi: [
                // "b88cb55e-39dc-11ee-9a36-0242ac110009", // AK 01
                "b88cce68-39dc-11ee-9a36-0242ac110009", // FR.IA.07
                // { id : 'b88cd5ac-39dc-11ee-9a36-0242ac110009'}, // FR.IA.09 (Pertanyaan Wawancara)
                "b88cd9a8-39dc-11ee-9a36-0242ac110009", // FR.IA.10 (Klarifikasi Bukti Pihak Ketiga)
                "b88c979a-39dc-11ee-9a36-0242ac110009", // FR.AK.02 REKAMAN ASESMEN
                // { id : 'b88c9b46-39dc-11ee-9a36-0242ac110009'}, // FR.APL.01 (Permohonan Sertifikasi Kompetensi)
                // { id : 'b88c9f10-39dc-11ee-9a36-0242ac110009'}, //	FR.APL.02 (Assessment Mandiri)
                // "6a00c792-3ab6-11ee-92d6-0242ac110009", // FR.MAPA.01
                // "b88ca64a-39dc-11ee-9a36-0242ac110009", // FR.MAPA.02
                "b88ce4a2-39dc-11ee-9a36-0242ac110009", // FR.AK.05 (Laporan Assessment)
                "b88cb90a-39dc-11ee-9a36-0242ac110009", // FR.IA.01 (Ceklis Observasi Aktivitas di Tempat Kerja atau Tempat Kerja Simulasi)
                "b88cbc8e-39dc-11ee-9a36-0242ac110009", // FR.IA.02 (Tugas Praktek Demonstrasi)
                "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.03
                //TAMBAHAN
                // "b88c93e4-39dc-11ee-9a36-0242ac110009", // Ceklis Konsultasi Pra Assessment
                "b88ce114-39dc-11ee-9a36-0242ac110009", // FR.AK.03
                "b88cb158-39dc-11ee-9a36-0242ac110009", // FR.AK.04
                "b88cf550-39dc-11ee-9a36-0242ac110009", // Daftar hadir Assessment.
            ],
            dokumen_peninjau: [
                "d3edc0c2-3b1c-11ee-a9ed-0242ac110009", // FR.VA Sesaat Asesmen
                "b88ce83a-39dc-11ee-9a36-0242ac110009", //FR.AK.06 (Meninjau Proses Assessment)
                "b88c9006-39dc-11ee-9a36-0242ac110009", //FR.IA.11 (Ceklis Meninjau Instrumen Assessment)
            ],

            dokumen_esign_asesi: [
                "b88cb90a-39dc-11ee-9a36-0242ac110009", // FR.IA.01 (Ceklis Observasi Aktivitas di Tempat Kerja atau Tempat Kerja Simulasi)
                "b88cc026-39dc-11ee-9a36-0242ac110009", // FR.IA.03
                "b88cce68-39dc-11ee-9a36-0242ac110009", // FR.IA.07
                "b88c979a-39dc-11ee-9a36-0242ac110009", // FR.AK.02 REKAMAN ASESMEN
                "b88cb55e-39dc-11ee-9a36-0242ac110009", // AK 01
                "b88cd214-39dc-11ee-9a36-0242ac110009", // FR.IA.08
                "b88cd5ac-39dc-11ee-9a36-0242ac110009", // FR.IA.09 (Pertanyaan Wawancara)
            ],

            skk: {
                nama: "",
                url: "",
            },
            skema_sertifikasi_dokumen: {
                nama: "",
                url: "",
            },
            metode_dokumen: null,
            data_asesmen: [],
            data_asesi: [],
            data_asesor: [],
            data_pra_asesmen: [],
            next_status: [],
            data_asesor_kompetensi: [],
            data_asesor_peninjau: [],
            asesmen_dokumen_id_selected: null,
            dokumen_id_selected: null,
            aksi_dokumen: null,
            status_pengisian_dokumen: null,
            asesi_id: null
        };
    },
    mounted() {
        let loadInputScript = document.createElement("script");
        loadInputScript.setAttribute("src", process.env.VUE_APP_BACKEND_URL + "/js/input.js");
        document.head.appendChild(loadInputScript);
        this.getDetailAsesmen();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("DD MMMM YYYY");
        },
        getDetailAsesmen() {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/detail",
                params: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        console.log(response_data_fix);
                        self.data_asesmen = response_data_fix.data_asesmen;

                        self.data_asesor = response_data_fix.data_asesor;
                        self.data_asesor_kompetensi = [];
                        self.data_asesor_peninjau = [];
                        self.data_asesor.map((asesor) => {
                            if (asesor?.sebagai == "Asesor Kompetensi") {
                                self.data_asesor_kompetensi.push(asesor);
                            } else if (asesor?.sebagai == "Asesor Peninjau") {
                                self.data_asesor_peninjau.push(asesor);
                            }
                        });
                        self.hak_akses = response_data_fix.hak_akses;
                        self.sebagai_asesor = response_data_fix.sebagai_asesor;
                        console.log(self.data_asesmen.metode_id);
                        var asesi_raw = [];
                        response_data_fix.data_asesi.map((data_asesi) => {
                            //metode portofolio
                            if (data_asesi.metode_sertifikasi_id == "99defda6-5868-43e7-bbf4-3975df6b17ae") {
                                data_asesi.testing = "portofolio";
                                data_asesi.metode_dokumen = self.metode_portofolio;
                            } else if (data_asesi.metode_sertifikasi_id == "dc187ee2-3a5c-11ee-bbe3-0242ac110009") {
                                data_asesi.testing = "observasi";
                                data_asesi.metode_dokumen = self.metode_observasi;
                            } else {
                                data_asesi.testing = "dit";
                                data_asesi.metode_dokumen = self.metode_portofolio;
                            }
                            asesi_raw.push(data_asesi);
                        });
                        self.data_asesi = asesi_raw;
                        self.skk.url = response_data_fix.skk_url;
                        self.skema_sertifikasi_dokumen.url = response_data_fix.skema_asertifikasi_dokumen_url;
                        self.skk.nama = response_data_fix.skk_nama;
                        self.dokumen_all = self.dokumen_isian;
                        self.next_status = response_data_fix.next_status;
                        console.log(self.next_status);
                        // self.meta_dokumen = response_data_fix.data_asesmen_dokumen;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.meta.message,
                        });
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error,
                    });
                })
                .finally(function () {
                    self.loadingTable = false;
                    Swal.close();
                });
        },
        prosesTerbitRekomendasi() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/terbitkan-rekomendasi",
                data: {
                    id: self.id,
                    asesi: JSON.stringify(self.data_asesi),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_store_data)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.meta.code == 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil Terbitkan Hasil Rekomendasi",
                            text: "Halaman akan segera diperbarui",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                window.location.reload();
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.meta.message,
                        });
                    }
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        ModalPreviewDokumen() {
            this.showModalPreviewDokumen = true;
        },
        pengisianDokumen(asesmen_dokumen_id, dokumen_id, asesi_id) {
            let self = this;
            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.showModalPengisianDokumen = true;
            self.asesmen_dokumen_id_selected = asesmen_dokumen_id;
            self.dokumen_id_selected = dokumen_id;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/get-form-dokumen", {
                    params: {
                        asesmen_id: self.data_asesmen.id,
                        asesmen_dokumen_id: asesmen_dokumen_id,
                        dokumen_id: dokumen_id,
                        asesi_id: asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    console.log(response.data.html);
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getFormDokumen(id, dokumen_id, asesi_id, status_pengisian_dokumen) {
            let self = this;
            self.formPreviewDoc = null;
            self.showModalPreviewDokumen = true;
            self.loadingformPreviewDoc = true;
            self.dokumen_id_selected = dokumen_id;
            self.asesi_id = asesi_id;
            self.status_pengisian_dokumen = status_pengisian_dokumen;
            self.asesmen_dokumen_id_selected = id;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/preview-dokumen", {
                    params: {
                        asesmen_dokumen_id: id,
                        dokumen_id: dokumen_id,
                        asesi_id: asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.loadingformPreviewDoc = false;
                    self.formPreviewDoc = res_fix.data.pdf;
                    // is_ttd_asesi = false;
                    // if(res_fix.data.dokumen_asesmen.status_dokumen == 'sudah-input' && (dokumen_peninjau.includes(dokumen.dokumen_id) || metode_dokumen.includes(dokumen.dokumen_id))){
                    //     is_ttd_asesi = true;
                    // }
                    // self.is_ttd_asesi = is_ttd_asesi;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storeInputDokumen() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-dokumen");
            var mydata = new FormData(form[0]);
            mydata.append("asesmen_dokumen_id", self.asesmen_dokumen_id_selected);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/store-form-dokumen", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Pengisian dokumen berhasil disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.showModalPengisianDokumen = false;
                    self.getDetailAsesmen();
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        prosesPerubahanStatus(status_verifikasi = "terima") {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/ubah-status",
                data: {
                    id: self.id,
                    status: status_verifikasi,
                    catatan: self.catatan,
                    next_status: self.next_status?.id,
                    password: self.password,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.reload();
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        getPreviewSKK() {
            let self = this;
            self.showModalPreviewDokumen = true;
            self.loadingformPreviewDoc = false;
            self.formPreviewDoc = self.skk.url;
        },
        getPreviewSkemaSertifikasi() {
            let self = this;
            self.showModalPreviewDokumen = true;
            self.loadingformPreviewDoc = false;
            self.formPreviewDoc = self.skema_sertifikasi_dokumen.url;
        },
        konfirmasiPasswordEsignAsesi(){
            let self = this;
            self.showModalPassword = true;
            self.showModalPreviewDokumen = false;
        },
        submitEsignAsesi() {
            let self = this;
            self.showModalPassword = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/esign-asesi",
                data: {
                    asesmen_dokumen_id: self.asesmen_dokumen_id_selected,
                    dokumen_id: self.dokumen_id_selected,
                    asesi_id: self.asesi_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            // self.showModalValidasi = false;
                            // self.showModalAsesor = false;
                            // self.modalPasswordKetuaLSP = false;
                            // self.modalPassword = false;
                            // self.$router.push({ name: "all-daftar_muk" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Pra Asesmen</span>
                                </template>
                                <div v-if="data_asesmen.length == 0">
                                    <p class="text-center">Data Tidak Ditemukan</p>
                                </div>
                                <div v-else>
                                    <DetailPraAsesmen :id_pra_asesmen="data_asesmen.pra_asesmen_id" />
                                </div>
                            </b-tab>
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Verifikasi TUK</span>
                                </template>
                                <div v-if="data_asesmen.length == 0">
                                    <p class="text-center">Data Tidak Ditemukan</p>
                                </div>
                                <div v-else>
                                    <DetailVerifikasiTUK :id_verifikasi_tuk="data_asesmen.verifikasi_tuk_id" />
                                </div>
                            </b-tab>
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail Asesmen</span>
                                </template>
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th style="width: 30%">Nomor Jadwal Sertifikasi</th>
                                                    <td>{{ data_asesmen?.pra_asesmen?.nomor_surat_tugas }}</td>
                                                </tr>
                                                <tr>
                                                    <th>Skema Sertifikasi</th>
                                                    <td>{{ data_asesmen?.pra_asesmen?.skema_nama }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>Metode Sertifikasi</th>
                                                    <td>{{ data_asesmen?.metode_nama }}</td>
                                                </tr> -->
                                                <tr>
                                                    <th>Perihal</th>
                                                    <td>{{ data_asesmen.perihal }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>Tanggal Pelaksanaan</th>
                                                    <td>{{ data_asesmen.tanggal_asesmen }}</td>
                                                </tr> -->
                                                <tr>
                                                    <th>Status Asesmen</th>
                                                    <td>{{ data_asesmen.status_nama }}</td>
                                                </tr>
                                                <tr>
                                                    <th>Tempat Uji Kompetensi</th>
                                                    <td>{{ data_asesmen.tuk_nama }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <label for="tipe_menu">Daftar Asesor</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Skema Sertifikasi</th>
                                                    <th>Asesor</th>
                                                    <th>Jabatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="data_asesor.length == 0">
                                                    <td colspan="6" class="text-center">Data Tidak Ditemukan</td>
                                                </tr>
                                                <tr v-else v-for="(asesor, index) in data_asesor" :key="index">
                                                    <td class="text-center">
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>
                                                        {{ data_asesmen?.pra_asesmen?.skema_nama }}
                                                    </td>
                                                    <td>{{ asesor?.pegawai?.npk }} - {{ asesor?.asesor_nama }}</td>
                                                    <td>
                                                        {{ asesor?.sebagai }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <label for="tipe_menu">Daftar Asesi</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 15px">No.</th>
                                                    <th>Asesi</th>
                                                    <th>Nomor Registrasi</th>
                                                    <th>Metode</th>
                                                    <th>Asesor</th>
                                                    <th>Tanggal Asesmen</th>
                                                    <th>Daftar Dokumen</th>
                                                    <th>Rekomendasi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(asesi, index) in data_asesi" :key="index">
                                                    <td class="text-center">
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>{{ asesi?.asesi?.npk }} - {{ asesi.asesi_nama }}</td>
                                                    <td>
                                                        <div v-if="asesi?.no_registrasi">
                                                            {{ asesi.no_registrasi }}
                                                        </div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        {{ asesi.metode_sertifikasi_nama }}
                                                    </td>
                                                    <td>{{ asesi?.asesmen_asesor?.pegawai?.npk }} - {{ asesi?.asesmen_asesor?.asesor_nama }}</td>
                                                    <td>
                                                        {{ asesi.tanggal_pelaksanaan }}
                                                    </td>
                                                    <td>
                                                        <ol style="list-style-type: decimal">
                                                            <li
                                                                v-for="(dokumen, index_dokumen) in asesi.asesi_dokumen"
                                                                :key="index_dokumen"
                                                                v-bind:class="{
                                                                    'bg-warning': dokumen.status_dokumen == 'belum-input' && ((dokumen_peninjau.includes(dokumen.dokumen_id) && (sebagai_asesor == 'Asesor Peninjau' || sebagai_asesor == 'semua')) || (asesi.metode_dokumen.includes(dokumen.dokumen_id) && (sebagai_asesor == 'Asesor Kompetensi' || sebagai_asesor == 'semua'))) && data_asesmen.status_id == '17bec90c-55f5-11ee-91f0-0242ac110006',
                                                                    'bg-success': dokumen.status_dokumen == 'sudah-input' && ((dokumen_peninjau.includes(dokumen.dokumen_id) && (sebagai_asesor == 'Asesor Peninjau' || sebagai_asesor == 'semua')) || (asesi.metode_dokumen.includes(dokumen.dokumen_id) && (sebagai_asesor == 'Asesor Kompetensi' || sebagai_asesor == 'semua'))) && data_asesmen.status_id == '17bec90c-55f5-11ee-91f0-0242ac110006',
                                                                }">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        {{ dokumen.dokumen_nama }}
                                                                    </div>
                                                                    <div class="col text-end">
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <button v-if="dokumen.dokumen_id == 'b88cad66-39dc-11ee-9a36-0242ac110009' || dokumen.dokumen_id == '4c940cac-3bae-11ee-8a7e-0242ac110009' || dokumen.dokumen_id == '4c940cac-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSKK()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                                                <button v-else-if="dokumen.dokumen_id == '1b5dd154-3bae-11ee-8a7e-0242ac110009'" type="button" @click="getPreviewSkemaSertifikasi()" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                                                <button v-else type="button" v-on:click="getFormDokumen(dokumen.id, dokumen.dokumen_id, asesi.id, dokumen.status_dokumen)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div v-if="data_asesmen.status_id == '17bec90c-55f5-11ee-91f0-0242ac110006'">
                                                                                    <div v-if="sebagai_asesor == 'Asesor Peninjau' || sebagai_asesor == 'semua'">
                                                                                        <div v-if="dokumen_peninjau.includes(dokumen.dokumen_id)">
                                                                                            <button @click="pengisianDokumen(dokumen.id, dokumen.dokumen_id, asesi.id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Tinjau Dokumen</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-if="sebagai_asesor == 'Asesor Kompetensi' || sebagai_asesor == 'semua'">
                                                                                        <div v-if="asesi.metode_dokumen.includes(dokumen.dokumen_id)">
                                                                                            <button @click="pengisianDokumen(dokumen.id, dokumen.dokumen_id, asesi.id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Lengkapi Dokumen</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </td>
                                                    <td class="text-center">
                                                        <div v-if="data_asesmen.is_asesmen_terbit">
                                                            {{ asesi.rekomendasi }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="hak_akses">
                                    <div class="col-md-12 col-md-6">
                                        <div class="text-end" v-if="data_asesmen.status_id == '17bec90c-55f5-11ee-91f0-0242ac110006' && sebagai_asesor != 'Asesor Peninjau'">
                                            <!-- <div type="button" class="btn btn-primary btn-sm" v-on:click="modalHasilRekomendasi = true">Terbitkan Hasil Asesmen</div> -->
                                            <div type="button" class="btn btn-primary btn-sm" v-on:click="modalHasilRekomendasi = true"><i class="fas fa-save"></i> Proses Submit</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-md-6">
                                        <div class="text-end" v-if="data_asesmen.status_id == '001c4440-5600-11ee-b313-0242ac110006'">
                                            <div type="button" class="btn btn-success btn-sm" v-on:click="prosesPerubahanStatus">Selesaikan Proses Asesmen</div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="modalHasilRekomendasi" title="Terbitkan Hasil Rekomendasi" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
            <div class="row">
                <div class="form-group">
                    <label for="tipe_menu">Daftar Asesi</label>
                    <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                        <thead class="bg-dark text-center text-white">
                            <tr>
                                <th style="width: 15px">No.</th>
                                <th>Asesi</th>
                                <th>Nomor Registrasi</th>
                                <th style="width: 250px">Rekomendasi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(asesi, index) in data_asesi" :key="index">
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{ asesi.asesi_nama }}
                                </td>
                                <td>
                                    <div v-if="asesi?.no_registrasi">
                                        {{ asesi.no_registrasi }}
                                    </div>
                                    <div v-else>Data Belum Tersedia</div>
                                </td>
                                <td>
                                    <v-select :options="['K', 'BK']" v-model="asesi.rekomendasi" placeholder="Pilih Hasil Rekomendasi"> </v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="modalHasilRekomendasi = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" @click="prosesTerbitRekomendasi"><i class="fas fa-save"></i> Terbitkan Rekomendasi</button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="showModalPengisianDokumen" title="Pengisian Dokumen" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
            <div class="row">
                <form id="form-dokumen">
                    <div class="form-group">
                        <div v-html="form_dokumen_muk"></div>
                    </div>
                </form>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3" v-on:click="storeInputDokumen"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="showModalPreviewDokumen" title="Preview Dokumen" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
            <div class="row">
                <form @submit.prevent="StorePreviewMUK">
                    <div v-if="loadingformPreviewDoc"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-else v-bind:src="formPreviewDoc" width="100%" height="400"></iframe>
                    <div class="card-footer text-end" v-if="dokumen_esign_asesi.includes(dokumen_id_selected) && status_pengisian_dokumen == 'sudah-input' && data_asesmen.status_id == '17bec90c-55f5-11ee-91f0-0242ac110006'">
                        <button type="button" class="btn btn-success mt-3" @click="konfirmasiPasswordEsignAsesi()"><i class="fas fa-check"></i> Esign Asesi</button>
                    </div>
                    <div class="card-footer text-end" v-else>
                        <button type="button" class="btn btn-secodary mt-3" @click="showModalPreviewDokumen = false"><i class="fas fa-times"></i> Tutup</button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal v-model="showModalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
            <div class="row">
                <!-- <form @submit.prevent="prosesVerifikasiKehadiran"> -->
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <input type="password" class="form-control" v-model="password" placeholder="Password Anda" />
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3" v-on:click="submitEsignAsesi()"><i class="fas fa-save"></i> Konfirmasi</button>
                </div>
                <!-- </form> -->
            </div>
        </b-modal>
    </Layout>
</template>
<style scoped>
.required-field::before {
    content: "*";
    color: red;
}
.disabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
}
</style>
